import { motion } from "framer-motion";
import React from "react";

import { ReactComponent as Logo } from "../images/logo.svg";

const textVariants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export const LogoLanding = ({ citation }) => {
  return (
    <motion.div
      className="absolute flex items-center justify-center w-full"
      variants={{
        initial: {
          opacity: 1,
          height: "100%",
        },
        invisible: {
          opacity: 0,
          height: 0,
        },
      }}
      transition={{
        duration: 1.0,
      }}
      initial="initial"
      animate={citation ? "initial" : "invisible"}
    >
      <motion.div
        variants={textVariants}
        initial="initial"
        animate="visible"
        transition={{
          delay: 0.6,
          duration: 1.5,
        }}
      >
        <Logo className="w-full mb-2 bg-white" />
      </motion.div>
    </motion.div>
  );
};
