import React, { useRef, useState } from "react";
import { CgMenu, CgClose, CgFacebook, CgInstagram } from "react-icons/cg";
import SidebarContent from "./SidebarContent";

const Sidebar = () => {
  let sideBar = document.getElementById("mobile-nav");
  let mobileNavScreen = document.getElementById("mobile-nav-screen");

  const openSidebar = useRef(null);
  const closeSidebar = useRef(null);
  const [showSideNav, setShowSideNav] = useState(true);

  function sidebarHandler() {
    if (showSideNav) {
      sideBar.style.transform = "translateX(0px)";
      mobileNavScreen.style.zIndex = 10;
      openSidebar.current.classList.add("hidden");
      closeSidebar.current.classList.remove("hidden");
      setShowSideNav(false);
    } else {
      sideBar.style.transform = "translateX(-260px)";
      mobileNavScreen.style.zIndex = 0;
      closeSidebar.current.classList.add("hidden");
      openSidebar.current.classList.remove("hidden");
      setShowSideNav(true);
    }
  }

  return (
    <div id="mobile-nav-screen" className="flex flex-no-wrap h-screen fixed">
      <div className="w-64 hidden lg:flex lg:absolute bg-uniqspaces shadow h-full flex-col justify-between">
        <SidebarContent />
        <div id="social-links" className="flex space-x-4 mx-auto pb-24">
          <CgFacebook />
          <CgInstagram />
        </div>
      </div>
      <div
        className="w-64 bg-uniqspaces shadow h-screen flex-col justify-between lg:hidden  transition duration-150 ease-in-out"
        id="mobile-nav"
        style={{ transform: "translateX(-260px)" }}
      >
        <div
          className="h-10 w-10 bg-uniqspaces absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer z-40"
          id="mobile-toggler"
          onClick={sidebarHandler}
        >
          <div ref={openSidebar}>
            <CgMenu />
          </div>
          <div ref={closeSidebar} className="hidden">
            <CgClose />
          </div>
        </div>
        <SidebarContent sidebarHandler={sidebarHandler} />
        <div id="social-links" className="text-center w-full mt-32">
          <CgFacebook className="h-8 w-8 mx-2 inline-block" />
          <CgInstagram className="h-8 w-8 mx-2 inline-block" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
