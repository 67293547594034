import { useEffect } from "react";
import { ReactComponent as Logo } from "../images/logo.svg";

function SidebarContent({ sidebarHandler }) {
  const callHideSideNav = () => {
    if (sidebarHandler) {
      sidebarHandler();
    }
  };

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const navLi = document.querySelectorAll("#navbar ul li");

    window.onscroll = () => {
      var current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - 100) {
          current = section.getAttribute("id");
        }
      });

      navLi.forEach((li) => {
        li.classList.remove("activenav");
        if (li.classList.contains(current)) {
          li.classList.add("activenav");
        }
      });
    };
  }, []);

  return (
    <div id="navbar">
      <div className="flex items-center">
        <Logo className="h-40 w-40 m-auto" />
      </div>
      <ul className="mt-12 uppercase">
        <li
          onClick={callHideSideNav}
          className="home block w-full justify-between text-gray-300 hover:text-gray-500 text-center cursor-pointer py-4 scroll-smooth"
        >
          <a href="#home">Home</a>
        </li>
        <li
          onClick={callHideSideNav}
          className="ongoingprojects block w-full justify-between text-gray-300 hover:text-gray-500 text-center cursor-pointer py-4 scroll-smooth"
        >
          <a href="#ongoingprojects">Ongoing Projects</a>
        </li>
        <li
          onClick={callHideSideNav}
          className="ourworks block w-full justify-between text-gray-300 hover:text-gray-500 text-center cursor-pointer py-4 scroll-smooth"
        >
          <a href="#ourworks">Our Works</a>
        </li>
        <li
          onClick={callHideSideNav}
          className="contactus block w-full justify-between text-gray-300 hover:text-gray-500 text-center cursor-pointer py-4 scroll-smooth"
        >
          <a href="#contactus">Contact Us</a>
        </li>
      </ul>
    </div>
  );
}
export default SidebarContent;
