import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        name: "",
        mobile: "",
        email: "",
        message: "",
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));
    axios({
      method: "POST",
      url: "https://formspree.io/f/xwkyaggl",
      data: inputs,
    })
      .then((response) => {
        handleServerResponse(
          true,
          "Thank you, your message has been submitted."
        );
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };
  return (
    <main>
      <form onSubmit={handleOnSubmit}>
        <label
          htmlFor="name"
          className="uppercase text-sm text-gray-600 font-bold"
        >
          Name
        </label>
        <input
          id="name"
          type="text"
          className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
          onChange={handleOnChange}
          required
          value={inputs.name}
        />
        <label
          htmlFor="mobile"
          className="uppercase text-sm text-gray-600 font-bold"
        >
          Mobile
        </label>
        <input
          id="mobile"
          type="tel"
          className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
          onChange={handleOnChange}
          required
          value={inputs.mobile}
        />
        <label
          htmlFor="email"
          className="uppercase text-sm text-gray-600 font-bold"
        >
          Email
        </label>
        <input
          id="email"
          type="email"
          name="_replyto"
          className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
          onChange={handleOnChange}
          required
          value={inputs.email}
        />
        <label
          htmlFor="message"
          className="uppercase text-sm text-gray-600 font-bold"
        >
          Message
        </label>
        <textarea
          id="message"
          name="message"
          className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
          onChange={handleOnChange}
          required
          value={inputs.message}
        />
        <button type="submit" disabled={status.submitting}>
          {!status.submitting
            ? !status.submitted
              ? "Send"
              : "Sent"
            : "Sending..."}
        </button>
      </form>
      {status.info.error && (
        <div className="error">Error: {status.info.msg}</div>
      )}
      {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
    </main>
  );
};
export default ContactForm;
