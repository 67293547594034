import ContactForm from "./ContactForm";

function ContactUs() {
  return (
    <>
      <div className="max-w-screen bg-uniqspaces mt-4 md:mt-24 grid gap-8 grid-cols-1 md:grid-cols-2 px-4 md:px-12 lg:px-16 xl:px-12 py-16 lg:mx-24 bg-gray-100 text-gray-900 shadow-lg">
        <div className="flex flex-col justify-between">
          <div>
            <h2 className="text-4xl lg:text-5xl font-bold leading-tight">
              Lets talk about everything!
            </h2>
            <div className="text-gray-700 mt-4">
              Hate forms? Send us an email at{" "}
              <a href="mailto:mailus@uniqspaces.co.in" className=" underline">
                mailus@uniqspaces.co.in
              </a>{" "}
              instead.
            </div>
          </div>
          <div className="mt-2">
            <h3 className="text-xl lg:text-2xl font-bold leading-tight">
              Call us
            </h3>
            <p> 9123123123 </p>
          </div>
          <div className="mt-2">
            <h3 className="text-xl lg:text-2xl font-bold leading-tight">
              Reach us at
            </h3>
            <p> #11, 3rd floor, 11th cross, </p>
            <p> Chikkamaranahalli,</p>
            <p> New Bel Road, </p>
            <p> Bangalore - 560094</p>
          </div>
        </div>
        <ContactForm />
      </div>
    </>
  );
}
export default ContactUs;
