import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "tw-elements";
import { LogoLanding } from "./components/LogoLanding";
import Sidebar from "./components/Sidebar";
import Home from "./components/Home";
import OngoingProjects from "./components/OngoingProjects";
import OurWorks from "./components/OurWorks";
import ContactUs from "./components/ContactUs";

function App() {
  const [citation, setCitation] = useState(true);
  const [main, setMain] = useState(false);

  useEffect(() => {
    const ids = [
      setTimeout(() => setCitation(false), 3000),
      setTimeout(() => setMain(true), 3500),
    ];

    return () => ids.forEach((id) => clearTimeout(id));
  }, [setCitation]);

  return (
    <div className="App">
      <LogoLanding citation={citation} />
      <motion.div
        variants={{
          initial: {
            opacity: 0,
            display: "none",
          },
          visible: {
            opacity: 1,
            display: "block",
          },
        }}
        initial="initial"
        animate={main ? "visible" : "initial"}
        transition={{
          duration: 0.5,
        }}
      >
        <Sidebar />
        <div className="container mx-auto pt-2 px-10 md:px-4 lg:pl-64 scroll-smooth">
          <section id="home" className="pb-4">
            <Home />
          </section>
          <section
            id="ongoingprojects"
            className="sm:mt-10 md:mt-4 pb-4 sm:px-0 md:px-10 lg:px-2"
          >
            <OngoingProjects />
          </section>
          <section id="ourworks" className="mt-4 pb-4 sm:px-0 md:px-10 lg:px-2">
            <OurWorks />
          </section>
          <section
            id="contactus"
            className="mt-4 pb-4 mb-10 lg:mb-24 sm:px-0 md:px-10 lg:px-2"
          >
            <ContactUs />
          </section>
        </div>
      </motion.div>
    </div>
  );
}

export default App;
