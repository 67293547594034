function Home() {
  return (
    <>
      <p className="block text-center lg:text-right text-2xl md:text-6xl lg:text-8xl opacity-20 pb-8">
        Welcome
      </p>
      <div className="bg-white dark:bg-gray-800 overflow-hidden relative lg:flex lg:items-center">
        <div className="w-full sm:px-6 lg:py-1 lg:px-8 mt-2 text-center">
          <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span className="block">Every space tells a story</span>
          </h2>
          <p className="text-md mt-4 text-gray-400">
            We at UniQ Spaces can help to convert your space to a beautiful
            memory.
          </p>
          <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="mt-4 inline-flex rounded-md shadow">
              <a
                href="#contactus"
                className="py-2 px-4  bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                Get in touch
              </a>
            </div>
          </div>
          <img
            src={require("../images/image16.jpeg")}
            className="rounded-lg w-full mt-4 shadow-md"
            alt="Room"
          />
        </div>
        <div className="w-full sm:px-6 lg:py-1 lg:px-8 mt-2 text-center">
          <div>
            <img
              src={require("../images/image4.jpeg")}
              className="rounded-lg w-full mb-4 shadow-md"
              alt="Office"
            />
            <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
              <span className="block">Dreams do come true</span>
            </h2>
            <p className="text-md mt-4 text-gray-400">
              We at UniQ Spaces can help to convert your space to a beautiful
              memory.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
